<template>
  <div class="broker-presentation-view">
    <v-row>
      <v-col>
        <v-card class="pb-2">
          <v-container>
            <v-card-title>
              <span class="text-h5">Datos Principales</span>
            </v-card-title>
            <v-card-text color="primary">
              <v-row>
                <v-col cols="12" sm="12">
                  <div class="mb-2">
                    <h3>
                      Nombre
                    </h3>
                  </div>
                  <v-text-field
                    outlined
                    :rules="namePresentationRules"
                    v-model="presentation.nombre"
                    placeholder="Nombre"
                    hint="Ingresa un nombre descriptivo de la presentación"
                    required
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="mb-2">
                    <h3>
                      Expositor
                    </h3>
                  </div>
                  <v-select
                    :items="[
                      'Luis Ángel',
                      'Xool Balam',
                      'Expositor 1',
                      'Expositor 2',
                      'Expositor 3',
                      'Expositor 4',
                      'Expositor 5',
                      'Mirse Hau',
                      'Maribel Chi',
                      'Asael Tun'
                    ]"
                    placeholder="Expositor"
                    v-model="presentation.expositor"
                    outlined
                    hint="Selecciona nombre del expositor"
                    required
                  ></v-select>
                </v-col>
                <v-col cols="12" sm="6">
                  <div class="mb-2">
                    <h3>
                      Inmobiliaria
                    </h3>
                  </div>
                  <v-select
                    :items="[
                      'Inmobiliaria 1',
                      'Inmobiliaria 2',
                      'Inmobiliaria 3',
                      'Inmobiliaria 4',
                      'Inmobiliaria 5',
                      'Inmobiliaria 6'
                    ]"
                    placeholder="Inmobilaria"
                    v-model="presentation.inmobiliaria"
                    outlined
                    hint="Selecciona nombre de la inmobiliaria"
                    required
                  ></v-select>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-title>
              <span class="text-h5">Datos Generales</span>
            </v-card-title>
            <v-card-text color="primary">
              <v-row>
                <v-col cols="12" sm="6">
                  <div class="mb-2">
                    <h3>
                      Fecha
                    </h3>
                  </div>
                  <v-menu
                    ref="menu"
                    v-model="menu"
                    :close-on-content-click="false"
                    :return-value.sync="presentation.fecha"
                    transition="scale-transition"
                    offset-y
                    min-width="auto"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="presentation.fecha"
                        placeholder="Fecha"
                        outlined
                        readonly
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker v-model="date" no-title scrollable>
                      <v-spacer></v-spacer>
                      <v-btn text color="primary" @click="menu = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        color="primary"
                        @click="$refs.menu.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <div class="mb-2">
                    <h3>
                      Expositores
                    </h3>
                  </div>
                  <v-autocomplete
                    v-model="friends"
                    :disabled="isUpdating"
                    :items="presentation.asistentes"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label="Select"
                    item-text="name"
                    item-value="name"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        <v-avatar left>
                          <v-img :src="data.item.avatar"></v-img>
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-avatar>
                          <img :src="data.item.avatar" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.phone"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>

                <v-col cols="12">
                  <div class="mb-2">
                    <h3>
                      Audiencia
                    </h3>
                  </div>
                  <v-autocomplete
                    v-model="friends"
                    :disabled="isUpdating"
                    :items="presentation.audiencia"
                    filled
                    chips
                    color="blue-grey lighten-2"
                    label="Select"
                    item-text="name"
                    item-value="name"
                    multiple
                  >
                    <template v-slot:selection="data">
                      <v-chip
                        v-bind="data.attrs"
                        :input-value="data.selected"
                        close
                        @click="data.select"
                        @click:close="remove(data.item)"
                      >
                        <v-avatar left>
                          <v-img :src="data.item.avatar"></v-img>
                        </v-avatar>
                        {{ data.item.name }}
                      </v-chip>
                    </template>
                    <template v-slot:item="data">
                      <template v-if="typeof data.item !== 'object'">
                        <v-list-item-content
                          v-text="data.item"
                        ></v-list-item-content>
                      </template>
                      <template v-else>
                        <v-list-item-avatar>
                          <img :src="data.item.avatar" />
                        </v-list-item-avatar>
                        <v-list-item-content>
                          <v-list-item-title
                            v-html="data.item.name"
                          ></v-list-item-title>
                          <v-list-item-subtitle
                            v-html="data.item.phone"
                          ></v-list-item-subtitle>
                        </v-list-item-content>
                      </template>
                    </template>
                  </v-autocomplete>
                </v-col>
                <v-col cols="12">
                  <div class="mb-2">
                    <h3>
                      Imagen
                    </h3>
                  </div>
                  <v-file-input
                    accept="image/png, image/jpeg, image/bmp"
                    hint="Imagen de evidencia de la presentación"
                    outlined
                    prepend-icon=""
                    prepend-inner-icon="mdi-camera"
                    placeholder="Imagen"
                    @change="changeImgPresentation($event)"
                  ></v-file-input>
                </v-col>
                <v-col v-if="urlImagen" cols="12">
                  <v-img
                    :src="urlImagen"
                    height="325"
                    class="grey darken-4"
                  ></v-img>
                </v-col>
                <v-col cols="12" sm="12">
                  <div class="mb-2">
                    <h3>
                      Descripción
                    </h3>
                  </div>
                  <v-textarea
                    placeholder="Descripción"
                    auto-grow
                    outlined
                    v-model="presentation.descripcion"
                    rows="3"
                    row-height="25"
                  ></v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-container>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text @click="closeAddPresentationModal()">
              Cancelar
            </v-btn>
            <v-btn color="primary" @click="submitFormPresentation()">
              Guardar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import DefaultLayout from "@/components/layouts/DefaultLayout.vue";
export default {
  created() {
    this.$emit(`update:layout`, DefaultLayout);
  },
  data() {
    const srcs = {
      1: "https://cdn.vuetifyjs.com/images/lists/1.jpg",
      2: "https://cdn.vuetifyjs.com/images/lists/2.jpg",
      3: "https://cdn.vuetifyjs.com/images/lists/3.jpg",
      4: "https://cdn.vuetifyjs.com/images/lists/4.jpg",
      5: "https://cdn.vuetifyjs.com/images/lists/5.jpg"
    };
    return {
      date: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
        .toISOString()
        .substr(0, 10),
      menu: false,
      rules: [
        value =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!"
      ],
      presentationNoValues: {
        nombre: "",
        expositor: "",
        inmobiliaria: "",
        fecha: "",
        asistentes: [
          { name: "Expositor num 1", phone: "88447554", avatar: srcs[1] },
          { name: "Expositor num 2", phone: "88447554", avatar: srcs[2] },
          { name: "Expositor num 3", phone: "88447554", avatar: srcs[3] },
          { name: "Expositor num 4", phone: "88447554", avatar: srcs[2] },
          { name: "Expositor num 5", phone: "884475547441", avatar: srcs[4] },
          { name: "Expositor num 6", phone: "884475541231", avatar: srcs[5] },
          { name: "Expositor num 7", phone: "884475541231", avatar: srcs[1] },
          { name: "Expositor num 8", phone: "884475541224", avatar: srcs[3] }
        ],
        audiencia: [
          {
            name: "Broker Asistencia num 1",
            phone: "88447554",
            avatar: srcs[1]
          },
          {
            name: "Broker Asistencia num 2",
            phone: "88447554",
            avatar: srcs[2]
          },
          {
            name: "Broker Asistencia num 3",
            phone: "88447554",
            avatar: srcs[3]
          },
          {
            name: "Broker Asistencia num 4",
            phone: "88447554",
            avatar: srcs[2]
          },
          {
            name: "Broker Asistencia num 5",
            phone: "884475547441",
            avatar: srcs[4]
          },
          {
            name: "Broker Asistencia num 6",
            phone: "884475541231",
            avatar: srcs[5]
          },
          {
            name: "Broker Asistencia num 7",
            phone: "884475541231",
            avatar: srcs[1]
          },
          {
            name: "Broker Asistencia num 8",
            phone: "884475541224",
            avatar: srcs[3]
          }
        ],
        imagen: "",
        descripcion: ""
      },
      urlImagen: "",
      namePresentationRules: [v => !!v || "El nombre es requerido"],
      expositorPresentationRules: [v => !!v || "El expositor es requerido"],
      inmobiliariaPresentationRules: [
        v => !!v || "la inmobiliaria es requerida"
      ],
      fechaPresentationRules: [v => !!v || "La fecha es requerida"],
      descripcionPresentationRules: [v => !!v || "La descripción es requerida"],
      events: [],
      input: null,
      nonce: 0,
      autoUpdate: true,
      friends: ["Sandra Adams", "Britta Holt"],
      isUpdating: false,
      name: "Midnight Crew",
      people: [
        // TODO: https://github.com/vuetifyjs/vuetify/issues/15721
        // { header: 'Group 1' },
      ],
      title: "The summer breeze",
      timeout: null
    };
  },
  props: {
    propShowModal: {
      type: Boolean,
      default: false
    },
    presentationToEditObject: {
      type: Object,
      required: false,
      default() {
        return null;
      }
    }
  },
  watch: {
    propShowModal() {
      if (this.propShowModal) {
        if (this.presentationToEditObject) {
          this.presentation = this.presentationToEditObject;
          this.urlImagen = this.presentation.imagen;
        }
      }
    }
  },
  computed: {
    show: {
      get() {
        return this.propShowModal;
      },
      set(value) {
        if (!value) {
          this.$emit("close", this.updatedData);
        }
      }
    },
    timeline() {
      return this.events.slice().reverse();
    }
  },
  methods: {
    closeAddPresentationModal() {
      this.show = false;
    },
    changeImgPresentation(event) {
      if (event) {
        this.urlImagen = URL.createObjectURL(event);
      } else {
        this.urlImagen = "";
      }
      //this.presentation.imagen = URL.createObjectURL(event);
    },
    comment() {
      const time = new Date().toTimeString();
      this.events.push({
        id: this.nonce++,
        text: this.input,
        time: time.replace(/:\d{2}\sGMT-\d{4}\s\((.*)\)/, (match, contents) => {
          return ` ${contents
            .split(" ")
            .map(v => v.charAt(0))
            .join("")}`;
        })
      });

      this.input = null;
    },
    remove(item) {
      const index = this.friends.indexOf(item.name);
      if (index >= 0) this.friends.splice(index, 1);
    }
  }
};
</script>
